import React from 'react';
import ScrollToTop from '../../components/Common/ScrollTop';
import ContactInfo from '../../components/Contact/ContactInfo';
import { Card, Form, Button } from 'react-bootstrap';

const ContactMain = () => {

    function sendEmail(form) {
        // e.preventDefault();

        let data = {
            name: form.target[0].value,
            email: form.target[1].value,
            subject: form.target[2].value,
            message: form.target[3].value
        }

        // fetch('backend/send', {
        fetch('/mail.php', {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(
            (response) => (response.json())
        ).then((response) => {
            if (response.status === 'success') {
                alert("Message Sent.");
                this.resetForm()
            } else if (response.status === 'fail') {
                alert("Message failed to send.")
            }
        })
    }

    return (
        <React.Fragment>
            <div className='container'>
                <div className="rs-contact pt-180 ml-40 mr-40 mb-40 ">
                    <h2>Contact with <span className='contact-us-amt'>AMT Consultancy!</span></h2>
                    <Card className='container mb-40'>
                        <Card.Body>
                            <Form onSubmit={sendEmail}>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12'>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>First name & Last name</Form.Label>
                                            <Form.Control />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="email" />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Subject</Form.Label>
                                            <Form.Control />
                                        </Form.Group>
                                    </div>
                                    <div className='col-md-6 col-sm-12'>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control as="textarea" rows={8} />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className='align-end container'>
                                    <Button className='submit-bttn' variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                    <h4>Have Problems? We are waiting for you. !!!</h4>
                    <div className='row contact-box-container'>
                        <div className='mb-10 col-12'>
                            <ContactInfo
                                address={"Ankapedia Kızılcaşar Mahallesi, 1209.Sokak, No:18 İncek Ankara / Turkey"}
                                phone={"+90 312 284 00 33"}
                                location={{
                                    lat: 39.8267154,
                                    lng: 32.7219334
                                }}
                                zoom={17}
                                title="AMT Consultancy - AnkaPedia - ANKARA "
                                className="contact-box-l"
                            />
                        </div>
                    </div>
                    <ScrollToTop />
                </div>
            </div>
        </React.Fragment>

    );
}


export default ContactMain;