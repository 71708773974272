import React from 'react';
import BannerHomeTen from '../../components/Banner/BannerHomeTen';
import Publications from '../../components/Publications/Publications';
import bannerImg from '../../assets/img/choose/3.png';
import ScrollToTop from '../../components/Common/ScrollTop';

import analysis1 from '../../assets/analyzes/1.pdf';
import analysis2 from '../../assets/analyzes/2.pdf';
import analysis3 from '../../assets/analyzes/3.pdf';
import analysis4 from '../../assets/analyzes/4.pdf';
import analysis5 from '../../assets/analyzes/5.pdf';
import analysis6 from '../../assets/analyzes/6.pdf';
import analysis7 from '../../assets/analyzes/7.pdf';
import analysis8 from '../../assets/analyzes/8.pdf';
import analysis9 from '../../assets/analyzes/9.pdf';
import analysis10 from '../../assets/analyzes/10.pdf';
import analysis11 from '../../assets/analyzes/11.pdf';
import analysis12 from '../../assets/analyzes/12.pdf';
import analysis13 from '../../assets/analyzes/13.pdf';
import analysis14 from '../../assets/analyzes/14.pdf';
import analysis15 from '../../assets/analyzes/15.pdf';
import analysis16 from '../../assets/analyzes/16.pdf';

const AnalyzesMain = () => {

    function getContent() {
        return (
            <h4 className="title">
                The <span className="pink-color">supreme guide</span> in life is <span className="blue-color">science</span>.
            </h4>
        )
    }

    return (
        <React.Fragment>
            <BannerHomeTen isQuarterWidth content={getContent()} bannerImg={bannerImg} />
            <br />
            <br />
            <div className="file-container">
                <div className="row pdf-file-container">
                    <h3>Analyzes</h3>
                </div>

                <div className="container">
                    <div className="row pdf-file-container">
                        <div className="col-md-4">
                            <Publications file={analysis16} />
                        </div>
                        <div className="col-md-4">
                            <Publications file={analysis15} />
                        </div>
                        <div className="col-md-4">
                            <Publications file={analysis14} />
                        </div>
                        <div className="col-md-4">
                            <Publications file={analysis13} />
                        </div>
                        <div className="col-md-4">
                            <Publications file={analysis12} />
                        </div>
                        <div className="col-md-4">
                            <Publications file={analysis11} />
                        </div>
                        <div className="col-md-4">
                            <Publications file={analysis10} />
                        </div>
                        <div className="col-md-4">
                            <Publications file={analysis9} />
                        </div>
                        <div className="col-md-4">
                            <Publications file={analysis8} />
                        </div>
                        <div className="col-md-4">
                            <Publications file={analysis7} />
                        </div>
                        <div className="col-md-4">
                            <Publications file={analysis6} />
                        </div>
                        <div className="col-md-4">
                            <Publications file={analysis5} />
                        </div>
                        <div className="col-md-4">
                            <Publications file={analysis4} />
                        </div>
                        <div className="col-md-4">
                            <Publications file={analysis3} />
                        </div>
                        <div className="col-md-4">
                            <Publications file={analysis2} />
                        </div>
                        <div className="col-md-4">
                            <Publications file={analysis1} />
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop
                scrollClassName="scrollup purple-color"
            />
        </React.Fragment>
    )
}

export default AnalyzesMain;