import React from 'react';
import { Link } from 'react-router-dom';

const MenuItems = (props) => {
    const { activeMenu } = props;

    return (
        <>
            <li>
                <Link to="about" className={activeMenu === "/about" ? "active-menu" : ""}>About</Link>
            </li>
            {/* <li className='menu-item-has-children'><Link to="" as="/">Consulting Services</Link>
                <ul className="sub-menu">
                    <li className="menu-item-has-children">
                        <Link to="/">Health and Medicine</Link>
                    </li>
                    <li className="menu-item-has-children">
                        <Link to="/">Academic Issues</Link>
                    </li>
                    <li className="menu-item-has-children">
                        <Link to="/">Political Science, Democracy</Link>
                    </li>
                    <li className="menu-item-has-children">
                        <Link to="/">Sociological Problems</Link>
                    </li>
                    <li className="menu-item-has-children">
                        <Link to="/">Social Policies</Link>
                    </li>
                    <li className="menu-item-has-children">
                        <Link to="/">Social Perceptions</Link>
                    </li>
                    <li className="menu-item-has-children">
                        <Link to="/">Political Perceptions</Link>
                    </li>
                </ul>
            </li> */}
            <li>
                <Link to="/analyzes" className={activeMenu === "/analyzes" ? "active-menu" : ""}>Analyzes</Link>
            </li>
            <li>
                <Link to="/publications" className={activeMenu === "/publications" ? "active-menu" : ""}>Publications</Link>
            </li>
            <li>
                <Link to="/blog" className={activeMenu === "/blog" ? "active-menu" : ""}>Blog</Link>
            </li>
            <li>
                <Link to="/contact" className={activeMenu === "/contact" ? "active-menu" : ""}>Contact</Link>
            </li>
        </>
    );
}

export default MenuItems;