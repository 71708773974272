import React from 'react';
import OffWrap from '../../components/Layout/Header/OffWrap';
import HeaderStyleFive from '../../components/Layout/Header/HeaderStyleFive';
import Footer from '../../components/Layout/Footer';
import HomeThirteenMain from './HomeThirteenMain';

const HomeThirteen = () => {
    return (
        <React.Fragment>
            <OffWrap />
            <HeaderStyleFive
                parentMenu='home'
                secondParentMenu='multipage'
                headerClass='rs-header style3 modify1 header-transparent'
                offCanvas='enable'
                activeMenu='/home-6'
            />
            <HomeThirteenMain />
            <Footer />
        </React.Fragment>
    );
}


export default HomeThirteen;