import React from 'react';
import OwlCarousel from 'react-owl-carousel2';

import oecdNormalLogo from '../../assets/img/brand/OECD-color.png';
import oecdHoverLogo from '../../assets/img/brand/OECD-color.png';

import whoNormalLogo from '../../assets/img/brand/WHO-color.png';
import whoHoverLogo from '../../assets/img/brand/WHO-color.png';

import unNormalLogo from '../../assets/img/brand/UN-color.png';
import unHoverLogo from '../../assets/img/brand/UN-color.png';

import twbNormalLogo from '../../assets/img/brand/TWB-color.png';
import twbHoverLogo from '../../assets/img/brand/TWB-color.png';

import unicefNormalLogo from '../../assets/img/brand/UNICEF-color.png';
import unicefHoverLogo from '../../assets/img/brand/UNICEF-color.png';

import unescoNormalLogo from '../../assets/img/brand/UNESCO-color.png';
import unescoHoverLogo from '../../assets/img/brand/UNESCO-color.png';


const Brand = () => {

    const options = {
        items: 5,
        nav: false,
        dots: false,
        rewind: false,
        autoplay: true,
        stagePadding: 0,
        margin: 30,
        loop: true,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            },
            1500: {
                items: 5,
            }
        }
    };

    return (
        <div className="rs-partner style4 modify1 pt-80 xs-pt-40 pb-120 md-pb-80 xs-pb-40">
            <div className="container">
                <OwlCarousel options={options} >
                    <div className="partner-item">
                        <div className="logo-img">
                            <a target="_blank" href="https://www.oecd.org/">
                                <img className="hover-logo" src={oecdNormalLogo} alt="" />
                                <img className="main-logo" src={oecdHoverLogo} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a target="_blank" href="https://www.un.org/en/">
                                <img className="hover-logo" src={unNormalLogo} alt="" />
                                <img className="main-logo" src={unHoverLogo} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a target="_blank" href="https://www.worldbank.org/en/home/">
                                <img className="hover-logo" src={twbNormalLogo} alt="" />
                                <img className="main-logo" src={twbHoverLogo} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a target="_blank" href="https://www.who.int/">
                                <img className="hover-logo" src={whoNormalLogo} alt="" />
                                <img className="main-logo" src={whoHoverLogo} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a target="_blank" href="https://www.unicef.org/">
                                <img className="hover-logo" src={unicefNormalLogo} alt="" />
                                <img className="main-logo" src={unicefHoverLogo} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="partner-item">
                        <div className="logo-img">
                            <a target="_blank" href="https://www.unesco.org/en">
                                <img className="hover-logo" src={unescoNormalLogo} alt="" />
                                <img className="main-logo" src={unescoHoverLogo} alt="" />
                            </a>
                        </div>
                    </div>
                </OwlCarousel>
            </div>
        </div>
    );
}

export default Brand;