import React from 'react';
import BannerHomeTen from '../../components/Banner/BannerHomeTen';
import Publications from '../../components/Publications/Publications';
import bannerImg from '../../assets/img/banner/bannerhome-6.png';
import ScrollToTop from '../../components/Common/ScrollTop';
import SectionTitle from '../../components/Common/SectionTitle';

import pdf1 from '../../assets/publications/1.pdf';
import pdf2 from '../../assets/publications/2.pdf';
import pdf3 from '../../assets/publications/3.pdf';
import pdf4 from '../../assets/publications/4.pdf';
import pdf5 from '../../assets/publications/5.pdf';
import pdf6 from '../../assets/publications/6.pdf';
import pdf7 from '../../assets/publications/7.pdf';
import pdf8 from '../../assets/publications/8.pdf';
import pdf9 from '../../assets/publications/9.pdf';
import pdf10 from '../../assets/publications/10.pdf';
import pdf11 from '../../assets/publications/11.pdf';
import pdf12 from '../../assets/publications/12.pdf';
import pdf13 from '../../assets/publications/13.pdf';
import pdf14 from '../../assets/publications/14.pdf';
import pdf15 from '../../assets/publications/15.pdf';
import pdf16 from '../../assets/publications/16.pdf';
import pdf17 from '../../assets/publications/17.pdf';
import pdf18 from '../../assets/publications/18.pdf';
import pdf19 from '../../assets/publications/19.pdf';
import pdf20 from '../../assets/publications/20.pdf';
import pdf21 from '../../assets/publications/21.pdf';
import pdf22 from '../../assets/publications/22.pdf';
import pdf23 from '../../assets/publications/23.pdf';
import pdf24 from '../../assets/publications/24.pdf';
import pdf25 from '../../assets/publications/25.pdf';
import pdf26 from '../../assets/publications/26.pdf';
import pdf27 from '../../assets/publications/27.pdf';
import pdf28 from '../../assets/publications/28.pdf';

import b1 from '../../assets/publications/book-1.jpeg';
import b2 from '../../assets/publications/book-2.jpeg';
import b3 from '../../assets/publications/book-3.jpeg';
import b4 from '../../assets/publications/book-4.jpeg';
import b5 from '../../assets/publications/book-5.jpeg';
import b6 from '../../assets/publications/book-6.png';
import b7 from '../../assets/publications/book-7.jpeg';

const PublicationsMain = () => {

    function getContent() {
        return (
            <h4 className="title">
                If one day, <span className="pink-color">my words</span> are against <span className="blue-color">science</span>, choose science.
            </h4>
        )
    }

    return (
        <React.Fragment>
            <BannerHomeTen content={getContent()} bannerImg={bannerImg} />
            <br />
            <br />
            <SectionTitle
                sectionClass="sec-title2 text-center mb-50"
                titleClass="title testi-title"
                title="Publications"
                descClass="desc desc3"
                description="Our primary motivation was to make approaches both in our country and
                internationally by producing scientific explanations and solutions to sociological and socio-political
                issues that concern the society, such as education, higher education, politics, democracy, economic
                approaches, health, privatization, opposition, political parties, speech analysis of politicians,
                leadership, scientific facts, and scientific mistakes made, ethics."
            />
            <div className="file-container">
                <h3 className='row pdf-file-container mb-20'>Books</h3>
                <div className="pdf-file-container row mb-40">
                    <div className='col-sm-6 col-md-4 col-lg-2 mb-10'>
                        <img src={b7} />
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-2 mb-10'>
                        <img src={b6} />
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-2 mb-10'>
                        <img src={b5} />
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-2 mb-10'>
                        <img src={b4} />
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-2 mb-10'>
                        <img src={b3} />
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-2 mb-10'>
                        <img src={b2} />
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-2 mb-10'>
                        <img src={b1} />
                    </div>
                </div>

                <div className="row pdf-file-container">
                    <h3>Papers</h3>
                </div>

                <div className="container">
                    <div className="row pdf-file-container">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf28} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf27} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf26} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf25} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf24} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf23} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf22} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf21} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf20} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf19} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf18} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf17} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf16} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf15} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf14} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf13} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf12} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf11} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf10} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf9} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf8} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf7} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf6} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf5} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf4} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf3} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf2} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <Publications file={pdf1} />
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop
                scrollClassName="scrollup purple-color"
            />
        </React.Fragment>
    )
}

export default PublicationsMain;