
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';
import SingleService from '../../components/Service/SingleService';

// Service Image Icons
import serviceIcon1 from '../../assets/img/service/style10/1.png';
import serviceIcon2 from '../../assets/img/service/style10/2.png';
import serviceIcon3 from '../../assets/img/service/style10/3.png';
import serviceIcon4 from '../../assets/img/service/style7/2.png';
import serviceIcon5 from '../../assets/img/service/style2/main-img/5.png';
import serviceIcon6 from '../../assets/img/service/style3/main-img/3.png';

const Service = () => {

    return (
        <div id="rs-service" className="rs-services style9 pt-120 pb-120 md-pt-80 md-pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-50"
                    titleClass="title testi-title"
                    title="Who we are and what we do"
                    descClass="desc desc3"
                    description="We provide the right consultancy on health, politics, and sociological issues"
                />
                <div className="row mb-80">
                    <div className="col-lg-4 col-md-6 md-mb-30">
                        <SingleService
                            itemClass="services-item pink-bg"
                            serviceImage={serviceIcon4}
                            serviceURL="/"
                            Title="Logos"
                            Text="`think logically` or `logical arguments`"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 md-mb-30">
                        <SingleService
                            itemClass="services-item gray-light-bg"
                            serviceImage={serviceIcon5}
                            serviceURL="/"
                            Title="Ethos"
                            Text="`See themselves in the mirror impartially and unbiased evaluation with intuitively and integrity.` or `reliability`"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <SingleService
                            itemClass="services-item"
                            serviceImage={serviceIcon6}
                            serviceURL="/"
                            Title="Pathos"
                            Text="`understanding enthusiasm,` or `emotional referred to as arguments.`"
                        />
                    </div>
                </div>
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-50"
                    titleClass="title testi-title"
                    title="AMT Consultancy gives recommendations"
                    descClass="desc desc3"
                    description="Analyzes and offers suggestions for the solution of health problems in the health sector and correct approaches, evaluation of medical errors and revealing scientific facts and works for education, social policies, political approaches, and solutions."
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 md-mb-30">
                        <SingleService
                            itemClass="services-item"
                            serviceImage={serviceIcon1}
                            serviceURL="/"
                            Title="Consultancy for:"
                            Text="How to transform uncertainty into certainty and turn the problem into solution."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 md-mb-30">
                        <SingleService
                            itemClass="services-item gray-light-bg"
                            serviceImage={serviceIcon2}
                            serviceURL="/"
                            Title="Consultancy with:"
                            Text="See themselves in the mirror impartially and unbiased evaluation with intuitively and integrity."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <SingleService
                            itemClass="services-item pink-bg"
                            serviceImage={serviceIcon3}
                            serviceURL="/"
                            Title="Consultancy whom:"
                            Text="Verify what they know and know the truth for removing misperceptions in political approaches by predetermining them"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;