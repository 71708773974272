import React from 'react';
import BannerHomeTen from '../../components/Banner/BannerHomeTen';
import bannerImg from '../../assets/img/banner/banner-10.png';
import AboutSection2 from '../../pages/home-2/AboutSection';
import AboutSection3 from '../../pages/home-3/AboutSection';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';
import Diploma from '../../components/Project/';
import ScrollToTop from '../../components/Common/ScrollTop';

const AboutMain = () => {

    function getContent() {
        return (
            <h4 className="title">It is not enough to see <span className="blue-color">the horizon</span>,
                it is necessary to see<span className="pink-color"> beyond the horizon </span>
                to try and work to see it.
            </h4>
        )
    }

    return (
        <React.Fragment>
            <BannerHomeTen content={getContent()} bannerImg={bannerImg} />
            <div id="rs-service" className="rs-services style9 pt-80 md-pt-80 md-pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-50"
                        titleClass="title testi-title"
                        title="AMT Consultancy"
                        descClass="desc desc3"
                        description="Often the problems we encounter are made up of multiple nested equations. To solve the problem, an approach should be made by analyzing the inner pattern of this complexity well. Analysis of the internal complexity of the problem requires both knowledge and experience. It is necessary to solve the problems not by looking from the outside, but by seeing the inside. However, much more important is to prevent problems before they become complicated and to do what is necessary. For this, `amtconsultancy` helps you."
                    />
                </div>
            </div>
            <AboutSection2 />
            <AboutSection3 />
            <Diploma />

            <ScrollToTop
                scrollClassName="scrollup purple-color"
            />
        </React.Fragment>
    )
}

export default AboutMain;