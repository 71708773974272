import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';

import aboutImg from '../../assets/img/banner/style2/bnr-ly2.png';
import effectImg1 from '../../assets/img/about/effect-1.png';

const About = (props) => {
    return (
        <div id="rs-about" className="rs-about bg17 pt-80 pb-120 md-pt-80 md-pb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-30">
                        <div className="rs-animation-shape">
                            <div className="images">
                                <img src={aboutImg} alt="" />
                            </div>
                            <div className="middle-image2">
                                <img className="dance" src={effectImg1} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 pl-60 md-pl-14">
                        {/* Section Title Start */}
                        <SectionTitle
                            sectionClass="sec-title mb-30"
                            subtitleClass="sub-text style2"
                            subtitle="About Us!"
                            titleClass="title pb-38"
                            title="We Are Increasing Business Success With Science"
                            descClass="desc pb-36"
                            description="Our working principle is to think holistically and broadly, to take different ideas and
                            develop new ideas in order to produce useful solutions.Work is not done for the sake of business."
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;