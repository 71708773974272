import React from 'react';
import { Link } from 'react-router-dom';
import FooterBottom from './FooterBottom';


import Newsletter from '../../Common/Newsletter';

import footerLogo1 from '../../../assets/img/logo/logo1.png';

const Footer = (props) => {
    const { footerLogo, footerClass } = props;
    return (
        <footer className={footerClass ? footerClass : 'rs-footer'}>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="footer-logo">
                                <Link to="/" ><img src={footerLogo1} alt="Logo" /></Link>
                            </div>
                            <p>
                                <ul className="address-widget mt-10">
                                    <li>
                                        <i className="flaticon-email"></i>
                                        <div className="desc">
                                            <a href="mailto:muratatuncer@amtconsultancy.com">muratatuncer@amtconsultancy.com</a>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="footer-social mt-10 md-mb-30">
                                    <li>
                                        <a target="_blank" href="https://web.facebook.com/ProfDrMuratTuncer/?_rdc=1&_rdr"><i className="fa fa-facebook-f"></i></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://twitter.com/abmrt33"><i className="fa fa-twitter"></i></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.youtube.com/channel/UCa2cuYCQTPq6d9eacD6mSfA"><i className="fa fa-youtube"></i></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.instagram.com/dr.a.murattuncer/"><i className="fa fa-instagram"></i></a>
                                    </li>
                                </ul>
                            </p>
                        </div>
                        <div className="col-md-4">
                            <h3 className="widget-title">AnkaPedia - ANKARA </h3>
                            <ul className="address-widget">
                                <li>
                                    <i className="flaticon-location"></i>
                                    <div className="desc">Kızılcaşar Mahallesi, 1209.Sokak, No:18 İncek Ankara/Turkey</div>
                                </li>
                                <li>
                                    <i className="flaticon-call"></i>
                                    <div className="desc">
                                        <a href="tel:+90 312 284 00 33">+90 312 284 00 33</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <FooterBottom /> */}
        </footer >
    );
}

export default Footer;