import React from 'react';
import mkAtaturk from '../../assets/img/about/ataturk.png';
import bgImg from '../../assets/img/bg/bg-8.png';

const bgStyle = {
    backgroundImage: `url(${bgImg})`,
    'background-repeat': 'no-repeat',
    'background-position': 'center center',
    'background-size': 'cover'
}

const BannerHomeTenBlog = ({ content, bannerImg, bannerContentClass = "" }) => {

    return (
        <React.Fragment>
            {/* <!-- banner section start --> */}
            <div id="rs-team" className="rs-testimonial style8 pt-120 pb-120 md-pt-80 md-pb-80" style={bgStyle}>
                <div className="rs-banner style6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-7">
                                <div className={`${bannerContentClass} banner-content`}>
                                    {content}
                                    <div className='signature-wrapper'>
                                        <img
                                            className='signature'
                                            src={mkAtaturk}
                                            alt="AMT Consultancy Ataturk Signature."
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="images-part">
                        <img
                            src={bannerImg}
                            alt=""
                        />
                    </div>
                </div>
            </div>
            {/* <!-- banner section end --> */}

        </React.Fragment >
    );
}

export default BannerHomeTenBlog;