import React, { useState } from 'react';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import { Modal, Button } from 'react-bootstrap';

// Service Icons
import imgMain1 from '../../assets/img/service/style2/main-img/1.png';
import imgHover1 from '../../assets/img/service/style2/hover-img/1.png';

import imgMain2 from '../../assets/img/service/style2/main-img/2.png';
import imgHover2 from '../../assets/img/service/style2/hover-img/2.png';

import imgMain3 from '../../assets/img/service/style2/main-img/3.png';
import imgHover3 from '../../assets/img/service/style2/hover-img/3.png';

import imgMain4 from '../../assets/img/service/style2/main-img/4.png';
import imgHover4 from '../../assets/img/service/style2/hover-img/4.png';

import imgMain5 from '../../assets/img/service/style2/main-img/5.png';
import imgHover5 from '../../assets/img/service/style2/hover-img/5.png';

import imgMain6 from '../../assets/img/service/style2/main-img/6.png';
import imgHover6 from '../../assets/img/service/style2/hover-img/6.png';

import imgMain7 from '../../assets/img/service/style3/main-img/6.png';
import imgHover7 from '../../assets/img/service/style3/hover-img/6.png';

import imgMain8 from '../../assets/img/service/style3/main-img/8.png';
import imgHover8 from '../../assets/img/service/style3/hover-img/8.png';

import imgMain9 from '../../assets/img/service/style3/main-img/3.png';
import imgHover9 from '../../assets/img/service/style3/hover-img/3.png';

import imgMain10 from '../../assets/img/service/style3/main-img/2.png';
import imgHover10 from '../../assets/img/service/style3/hover-img/2.png';

import imgMain11 from '../../assets/img/service/style3/main-img/9.png';
import imgHover11 from '../../assets/img/service/style3/hover-img/9.png';

import imgMain12 from '../../assets/img/service/style3/main-img/7.png';
import imgHover12 from '../../assets/img/service/style3/hover-img/7.png';

const clarifyingIdeas = {
    header: "Clarifying ideas on health problems and erroneous medical approaches",
    description: "Preventable Medical Errors, Poor Mortality Rates, Lack of Transparency, High Costs of Care, Difficulty accessing healthcare, and inefficiencies are the primary health system problems, and nearly all of them are preventable by the right consultancy."
}
const democracyPerception = {
    header: "Democracy perception",
    description: "Political party behavior, wrong approaches, reasons for defeat, ways of success, voter behaviors, behavioral changes, voters and citizen satisfaction, Suggestions for resolving conflicts at the national and international level, international relations, diplomacy approaches."
}

const efficiency = {
    header: "Efficiency in advertising preparation",
    description: "The primary concern for marketers addressing the claims of the inefficiency of spending on advertising. We examine whether the best way can help increase overall advertising efficiency and good public perception. Perception management in marketing by preventing ad mistakes."
}
const earlyIdentification = {
    header: "Early identification of potential problems",
    description: "It is essential to determine and analyze potential issues in advance, take precautions, develop solution proposals before a problem arises, and choose a roadmap and different strategies. Prediction of social perception is essential to prevent possible problems."
}
const healthSystem = {
    header: "Health system & Medical problem",
    description: "Preventable Medical Errors, Poor Mortality Rates, Lack of Transparency, High Costs of Care, Difficulty accessing healthcare, and inefficiencies are the primary health system problems, and nearly all of them are preventable by the right consultancy."
}
const leadership = {
    header: "Leadership management",
    description: "The leadership feature requires personalized solution suggestions for every situation. In quantum leadership, all kinds of modalities should be applied when necessary. Creative leadership fundamentals should be figured out."
}
const management = {
    header: "Management and evaluation of public perception to advertising",
    description: "The primary concern for marketers addressing the claims of the inefficiency of spending on advertising. We examine whether the best way can help increase overall advertising efficiency and good public perception. Perception management in marketing by preventing ad mistakes."
}
const politics = {
    header: "Politics & Political parties",
    description: "Political party behavior, wrong approaches, reasons for defeat, ways of success, voter behaviors, behavioral changes, voters and citizen satisfaction, Suggestions for resolving conflicts at the national and international level, international relations, diplomacy approaches."
}
const perception = {
    header: "Perception of the public",
    description: "Political party behavior, wrong approaches, reasons for defeat, ways of success, voter behaviors, behavioral changes, voters and citizen satisfaction, Suggestions for resolving conflicts at the national and international level, international relations, diplomacy approaches."
}
const preventing = {
    header: "Preventing ads mistakes",
    description: "The primary concern for marketers addressing the claims of the inefficiency of spending on advertising. We examine whether the best way can help increase overall advertising efficiency and good public perception. Perception management in marketing by preventing ad mistakes."
}
const perceptionManagement = {
    header: "Perception management on the marketing",
    description: "The primary concern for marketers addressing the claims of the inefficiency of spending on advertising. We examine whether the best way can help increase overall advertising efficiency and good public perception. Perception management in marketing by preventing ad mistakes."
}
const predictionOfSocial = {
    header: "Prediction of social perception",
    description: "Preventable Medical Errors, Poor Mortality Rates, Lack of Transparency, High Costs of Care, Difficulty accessing healthcare, and inefficiencies are the primary health system problems, and nearly all of them are preventable by the right consultancy."
}

const ServiceTwo = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(undefined);

    function openDescriptionModal(content) {
        setIsModalOpen(true);
        setModalContent(content);
    }

    function closeModal() {
        setIsModalOpen(false);
        setModalContent(undefined);
    }

    function VerticalModalDescription(props) {
        return (
            <Modal
                show={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props.content.header}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {props.content.description}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <div onClick={closeModal} className='modal-btn-part'>Close</div>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <div className="rs-services style3 modify2 pt-120 md-pt-80">
            {isModalOpen && <VerticalModalDescription content={modalContent} />}
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-50"
                    titleClass="title testi-title"
                    title="Creative Consulting Services"
                    descClass="desc desc3"
                // description="-----------"
                />
                <div className="row">
                    <div className="col-lg-6 mb-10">
                        <SingleServiceFive
                            itemClass="services-item clickable light-purple-bg"
                            MainImg={imgMain1}
                            HoverImg={imgHover1}
                            Title="Clarifying ideas on health problems and erroneous medical approaches"
                            btnClass='d-none'
                            onClick={() => openDescriptionModal(clarifyingIdeas)}
                        />
                    </div>
                    <div className="col-lg-6 mb-10">
                        <SingleServiceFive
                            itemClass="services-item clickable light-purple-bg"
                            MainImg={imgMain2}
                            HoverImg={imgHover2}
                            Title="Democracy perception"
                            btnClass='d-none'
                            onClick={() => openDescriptionModal(democracyPerception)}
                        />
                    </div>
                    <div className="col-lg-6 mb-10">
                        <SingleServiceFive
                            itemClass="services-item clickable light-purple-bg"
                            MainImg={imgMain3}
                            HoverImg={imgHover3}
                            Title="Efficiency in advertising preparation"
                            btnClass='d-none'
                            onClick={() => openDescriptionModal(efficiency)}
                        />
                    </div>
                    <div className="col-lg-6 mb-10">
                        <SingleServiceFive
                            itemClass="services-item clickable light-purple-bg"
                            MainImg={imgMain4}
                            HoverImg={imgHover4}
                            Title="Early identification of potential problems"
                            btnClass='d-none'
                            onClick={() => openDescriptionModal(earlyIdentification)}
                        />
                    </div>
                    <div className="col-lg-6 mb-10">
                        <SingleServiceFive
                            itemClass="services-item clickable light-purple-bg"
                            MainImg={imgMain5}
                            HoverImg={imgHover5}
                            Title="Health system & Medical problem"
                            btnClass='d-none'
                            onClick={() => openDescriptionModal(healthSystem)}
                        />
                    </div>
                    <div className="col-lg-6 mb-10">
                        <SingleServiceFive
                            itemClass="services-item clickable light-purple-bg"
                            MainImg={imgMain6}
                            HoverImg={imgHover6}
                            Title="Leadership management"
                            btnClass='d-none'
                            onClick={() => openDescriptionModal(leadership)}
                        />
                    </div>
                    <div className="col-lg-6 mb-10">
                        <SingleServiceFive
                            itemClass="services-item clickable light-purple-bg"
                            MainImg={imgMain7}
                            HoverImg={imgHover7}
                            Title="Management and evaluation of public perception to advertising"
                            btnClass='d-none'
                            onClick={() => openDescriptionModal(management)}
                        />
                    </div>
                    <div className="col-lg-6 mb-10">
                        <SingleServiceFive
                            itemClass="services-item clickable light-purple-bg"
                            MainImg={imgMain8}
                            HoverImg={imgHover8}
                            Title="Politics & Political parties"
                            btnClass='d-none'
                            onClick={() => openDescriptionModal(politics)}
                        />
                    </div>
                    <div className="col-lg-6 mb-10">
                        <SingleServiceFive
                            itemClass="services-item clickable light-purple-bg"
                            MainImg={imgMain9}
                            HoverImg={imgHover9}
                            Title="Perception of the public"
                            btnClass='d-none'
                            onClick={() => openDescriptionModal(perception)}
                        />
                    </div>
                    <div className="col-lg-6 mb-10">
                        <SingleServiceFive
                            itemClass="services-item clickable light-purple-bg"
                            MainImg={imgMain10}
                            HoverImg={imgHover10}
                            Title="Preventing ads mistakes"
                            btnClass='d-none'
                            onClick={() => openDescriptionModal(preventing)}
                        />
                    </div>
                    <div className="col-lg-6 mb-30">
                        <SingleServiceFive
                            itemClass="services-item clickable light-purple-bg"
                            MainImg={imgMain11}
                            HoverImg={imgHover11}
                            Title="Perception management on the marketing"
                            btnClass='d-none'
                            onClick={() => openDescriptionModal(perceptionManagement)}
                        />
                    </div>
                    <div className="col-lg-6 mb-30">
                        <SingleServiceFive
                            itemClass="services-item clickable light-purple-bg"
                            MainImg={imgMain12}
                            HoverImg={imgHover12}
                            Title="Prediction of social perception"
                            btnClass='d-none'
                            onClick={() => openDescriptionModal(predictionOfSocial)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServiceTwo;