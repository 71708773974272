import React from 'react';
import OffWrap from '../../components/Layout/Header/OffWrap';
import HeaderStyleFive from '../../components/Layout/Header/HeaderStyleFive';
import Footer from '../../components/Layout/Footer';
import PublicationsMain from './Publications';


const Publications = () => {
    return (
        <React.Fragment>
            <React.Fragment>
                <OffWrap />
                <HeaderStyleFive
                    parentMenu='about'
                    secondParentMenu='multipage'
                    headerClass='rs-header style3 modify1 header-transparent'
                    offCanvas='enable'
                    activeMenu='/publications'
                />
                <PublicationsMain />
                <Footer />
            </React.Fragment>
        </React.Fragment>
    );
}

export default Publications;