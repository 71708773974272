import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';

import aboutImg from '../../assets/img/about/amt-person.png';
import effectImg1 from '../../assets/img/about/effect-1.png';

const About = () => {

    return (
        <div id="rs-about" className="rs-about bg17a pt-120 pb-120 md-pt-80 md-pb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 pl-60 md-pl-14">
                        {/* Section Title Start */}
                        <SectionTitle
                            sectionClass="sec-title mb-30"
                            subtitleClass="sub-text style2"
                            subtitle="Who is AMT!"
                            titleClass="title pb-38"
                            title="Abdullah Murat Tuncer"
                            descClass="desc pb-36"
                            description="After nearly thirty years of management experiences as chief 
                            assistant, chief physician institute directorate, and directorate of ministry 
                            of health, cancer control department and rector of Hacettepe University I turned 
                            to sociology education and political science after being an academician and 
                            highest responsibilities in many national and international academic institutions."
                            secondDescClass="desc pb-36"
                            secondDescription="It makes me happy to share my experiences with those who need it."
                        />
                    </div>
                    <div className="col-lg-6 md-mb-30">
                        <div className="rs-animation-shape">
                            <div className="images">
                                <img src={aboutImg} alt="" />
                            </div>
                            <div className="middle-image2">
                                <img className="dance" src={effectImg1} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;