import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const RSMobileMenu = ({ setMenuOpen, menuOpen, activeMenu }) => {

	return (
		<div className="container relative">
			<div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
				<div className="mobile-menu">
					<ul className="nav-menu">
						<li>
							<Link to="/about" className={activeMenu === "/about" ? "active-menu" : ""}>About</Link>
						</li>
						<li>
							<Link to="/publications" className={activeMenu === "/publication" ? "active-menu" : ""}>Publications</Link>
						</li>
						<li>
							<Link to="/blog" className={activeMenu === "/blog" ? "active-menu" : ""}>Blog</Link>
						</li>
						<li>
							<Link to="/contact" className={activeMenu === "/contact" ? "active-menu" : ""}>Contact</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default RSMobileMenu;