import React from 'react';
import HeaderStyleFive from '../../components/Layout/Header/HeaderStyleFive';
import Footer from '../../components/Layout/Footer';
import YouTube from 'react-youtube';
import BannerHomeTenBlog from '../../components/Banner/BannerHomeTenBlog';
import bannerImg from '../../assets/img/blog/inner/3bgfree.png';


const opts = {
    height: '300',
    width: '100%',
    playerVars: {
        autoplay: 0,
    },
};


const Blog = () => {
    function getContent() {
        return (
            <h4 className="title">Victory is for those who can say <span className="blue-color">“Victory is mine”</span>,
                Success is for those who can begin saying <span className="pink-color"> “I will succeed.” </span> and say <span className="pink-color">“I have succeeded.” </span>
                in the end.
            </h4>
        )
    }

    return (
        <React.Fragment>
            <HeaderStyleFive
                parentMenu='home'
                secondParentMenu='multipage'
                headerClass='rs-header style3 modify1 header-transparent'
                offCanvas='enable'
                activeMenu='/home-6'
            />
            <BannerHomeTenBlog bannerContentClass='blog' content={getContent()} bannerImg={bannerImg} />
            <div className='container mt-80 mb-40'>
                <div className='row'>
                    <div className='mt-20 mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="gUlxTI-MkgQ" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="F43avn6uuNo" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="FKkF6RtpPYM" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="x5dUogtcNKg" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="R-pTpbWKL_I" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="DxN_KKz4FXU" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="5mozVazg9fs" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="fguJbDef-cA" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="v11u_l_LU3U" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="T_tEImEV-LM" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="1iUpC9OS5BY" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="yIWRrWxUdgY" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="VxDJ8CIrI_o" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="2QJ65dPw8kA" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="IEpoTpAMA_g" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="xT41_jDq9KM" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="24MOmX_phDg" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="vJptism38CU" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="rxJqp3FIXGo" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="LeBx1ILRuu8" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="NC1lfUIeVlE" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="3RNXuHa0CIc" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="AdQRgdWHgyQ" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="6cO1PTWUyBo" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="4TsYRxiyyTU" opts={opts} />
                    </div>
                    <div className='mt-20 col-xl-4 col-md-6 col-sm-12'>
                        <YouTube videoId="Gva43ddw8lw" opts={opts} />
                    </div>
                </div>
            </div>
            <Footer />

        </React.Fragment>
    );
}


export default Blog;

