import React from 'react';
import BannerHomeThirteen from '../../components/Banner/BannerHomeThirteen';
import About from './AboutSection';
import Service from './ServiceSection';
import ServiceTwo from './ServiceSectionTwo';
import Brand from './BrandSection';
import CTA from './CTASection';
import ScrollToTop from '../../components/Common/ScrollTop';

const HomeThirteenMain = () => {
	return (
		<React.Fragment>
			{/* Slider-start */}
			<BannerHomeThirteen />
			{/* <BannerHomeSix /> */}

			{/* Service-start */}
			<Service />
			{/* Service-end */}

			{/* About-area-start */}
			<About />
			{/* About-area-end */}

			{/* Service-area-start */}
			<ServiceTwo />
			{/* Service-area-end */}

			{/* Brand-start */}
			<Brand />
			{/* Brand-end */}

			{/* CTA-area-start */}
			<CTA />
			{/* CTA-area-end */}

			{/* scrolltop-start */}
			<ScrollToTop
				scrollClassName="scrollup purple-color"
			/>
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default HomeThirteenMain;