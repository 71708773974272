import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`


const Publications = ({ file }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const goToPrevPage = () =>
        setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

    const goToNextPage = () => {
        setPageNumber(
            pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
        );
    }

    return (
        <div className='flex-display'>
            <div className="file-publications">
                <Document
                    file={file}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className="document-file"
                >
                    <Page className="file-page-canvas" height={550} scale={0.8} pageNumber={pageNumber} />
                </Document>
            </div>
            <div className='pdf-nav-container'>
                <div className="pdf-nav">
                    <div onClick={goToPrevPage}>
                        <i className="fa fa-angle-left"></i>
                    </div>
                </div>
                <div className="pdf-nav">
                    <a target="_blank" href={file}>
                        <div>
                            <i className="fa fa-file-pdf-o"></i>
                        </div>
                    </a>
                </div>
                <div className="pdf-nav">
                    <div onClick={goToNextPage}>
                        <i className="fa fa-angle-right"></i>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Publications;