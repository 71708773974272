import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SingleProject from './SingleProject';


import certificate from '../../assets/img/diplomas/certificate.jpg';
import diploma1 from '../../assets/img/diplomas/diploma1.jpg';
import diploma2 from '../../assets/img/diplomas/diploma2.jpg';
import onurbelgesi from '../../assets/img/diplomas/onurbelgesi.jpg';
import onurbelgesi1 from '../../assets/img/diplomas/onurbelgesi1.jpg';
import onurbelgesi2 from '../../assets/img/diplomas/onurbelgesi2.jpg';
import onurbelgesi3 from '../../assets/img/diplomas/onurbelgesi3.jpg';
import skolaBasari from '../../assets/img/diplomas/skolabasari.png';
import conley from '../../assets/img/diplomas/conley.jpg';
import reviewing from '../../assets/img/diplomas/reviewing.jpg';

const Project = () => {

    const options = {
        items: 6,
        nav: true,
        dots: false,
        margin: 30,
        rewind: false,
        autoplay: true,
        stagePadding: 30,
        loop: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        center: false,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                nav: false,
            },
            768: {
                items: 2,
                nav: false,
                stagePadding: 0,
            },
            992: {
                items: 2,
                nav: false,
                stagePadding: 0,
            },
            1200: {
                items: 3,
                nav: false,
                stagePadding: 0,
            },
            1500: {
                items: 3,
                nav: false,
                stagePadding: 0,
            }
        }
    };

    return (
        <React.Fragment>
            <div id="rs-portfolio" className="rs-project style1 pt-110 pb-110 md-pt-80">
                <div className="container">
                    <OwlCarousel options={options} >
                        <SingleProject
                            itemClass="project-item"
                            projectImage={reviewing}
                            Title="Journal of Advances in Medicine and Medical Research"
                            Category="Certificate of Excellence in Reviewing"
                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={conley}
                            Title="Doctor of Philosophy in Political Science"
                            Category="Diploma"
                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={skolaBasari}
                            Title="Political Psychology Education"
                            Category="Certificate of achievement"
                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={certificate}
                            Title="The Board of
                            International Tournal of Research and
                            Innovation in Social Science"
                            Category="Certificate"
                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={diploma1}
                            Title="Foreign Policy and Diplomacy"
                            Category="Diploma"
                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={diploma2}
                            Title="Political Science"
                            Category="Diploma"
                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={onurbelgesi}
                            Title="Foreign Policy and Diplomacy"
                            Category="Certificate of honor"
                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={onurbelgesi1}
                            Title="Foreign Policy and Diplomacy"
                            Category="Certificate of honor"
                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={onurbelgesi2}
                            Title="Foreign Policy and Diplomacy"
                            Category="Certificate of honor"
                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={onurbelgesi3}
                            Title="Foreign Policy and Diplomacy"
                            Category="Certificate of honor"
                        />
                    </OwlCarousel>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Project