import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import canvasLogo from '../../../assets/img/logo/logo1.png';

const CanvasMenu = () => {

    const canvasMenuRemove = () => {
        document.body.classList.remove('nav-expanded');
    };

    return (
        <React.Fragment>
            <nav className="right_menu_togle hidden-md">
                <div className="close-btn">
                    <div className="nav-link">
                        <a onClick={canvasMenuRemove} id="nav-close" className="humburger nav-expander" href="#">
                            <span className="dot1"></span>
                            <span className="dot2"></span>
                            <span className="dot3"></span>
                            <span className="dot4"></span>
                            <span className="dot5"></span>
                            <span className="dot6"></span>
                            <span className="dot7"></span>
                            <span className="dot8"></span>
                            <span className="dot9"></span>
                        </a>
                    </div>
                </div>
                <div className="canvas-logo">
                    <Link to="/"><img src={canvasLogo} alt="logo" /></Link>
                </div>
                <div className="offcanvas-text">
                    <p>
                        AMT Consultancy provides opinions, analysis, and recommendations to organizations or individuals, based on their own expertise and experiences essentially to improve performance.</p>
                </div>
                <div className="canvas-contact">
                    <div className='row'>
                        <div className='col-6'>
                            <div className="address-area">
                                <div className="address-list">
                                    <div className="info-icon">
                                        <i className="fa fa-map-marker"></i>
                                    </div>
                                    <div className="info-content">
                                        <h4 className="title">Address - Ankara</h4>
                                        <em>Tepe Prime C Blok No:35</em>
                                        <em>Mustafa Kemal Mahallesi</em>
                                        <em>Çankaya Ankara/Turkey</em>
                                    </div>
                                </div>
                                <div className="address-list">
                                    <div className="info-icon">
                                        <i className="fa fa-phone"></i>
                                    </div>
                                    <div className="info-content">
                                        <h4 className="title">Phone</h4>
                                        <em><a href="tel:+90 312 284 00 33">+90 312 284 00 33</a></em>
                                    </div>
                                </div>
                                <div className="address-list">
                                    <div className="info-icon">
                                        <i className="fa fa-envelope-open"></i>
                                    </div>
                                    <div className="info-content">
                                        <h4 className="title">Email</h4>
                                        <em><a href="mailto:muratatuncer@amtconsultancy.com">muratatuncer@amtconsultancy.com</a></em>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul className="social">
                            <li>
                                <a target="_blank" href="https://web.facebook.com/ProfDrMuratTuncer/?_rdc=1&_rdr"><i className="fa fa-facebook-f"></i></a>
                            </li>
                            <li>
                                <a target="_blank" href="https://twitter.com/abmrt33"><i className="fa fa-twitter"></i></a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.youtube.com/channel/UCa2cuYCQTPq6d9eacD6mSfA"><i className="fa fa-youtube"></i></a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.instagram.com/dr.a.murattuncer/"><i className="fa fa-instagram"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    );
}

export default CanvasMenu;