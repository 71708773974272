import React from 'react';
import SectionTitle from '../Common/SectionTitle';
import GoogleMapReact from 'google-map-react';

import favicon from '../../assets/img/logo/favicon.png';

const LocationPin = () => (
    <div className="pin">
        <img src={favicon} />
    </div>
)

const ContactInfo = (props) => {
    const { location, zoom = 11, phone, address, email, title, className } = props;
    return (
        <div className={`${className} contact-box`}>
            <SectionTitle
                sectionClass="sec-title"
                subtitleClass="sub-text new-text white-color"
                subtitle="Let's Talk"
                titleClass="title white-color"
                title={title}
            />
            <div className='map-amt-google mb-12'>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyBGJ2j1SZmg6tht3u-8xNrB3XjM4Z5YpBo" }}
                    defaultCenter={{ ...location }}
                    defaultZoom={zoom}
                >
                    <LocationPin
                        lat={location.lat}
                        lng={location.lng}
                    />
                </GoogleMapReact>
            </div>
            <div className="address-box mb-12 mt-3">
                <div className="address-icon">
                    <i className="fa fa-map-marker"></i>
                </div>
                <div className="address-text">
                    <span className="label">Address:</span>
                    <div className="desc">{address}</div>
                </div>
            </div>
            <div className='row'>
                {/* <div className="address-box mb-12 col-md-7 col-sm-12">
                    <div className="address-icon">
                        <i className="fa fa-home"></i>
                    </div>
                    <div className="address-text">
                        <span className="label">Email:</span>
                        <a href={`mailto:${email}`}>{email}</a>
                    </div>
                </div> */}
                <div className="address-box mb-12 col-md-6 col-sm-12">
                    <div className="address-icon">
                        <i className="fa fa-phone"></i>
                    </div>
                    <div className="address-text">
                        <span className="label">Phone:</span>
                        <a href={`tel:${phone}`}>{phone}</a>
                    </div>
                </div>
            </div>
        </div >
    );

}

export default ContactInfo;